.dapp-topbar {
  width: 100%;
  align-items: center;
  a,
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    min-height: 40px !important;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
}
.tablet .dapp-topbar {
  justify-content: space-between;
}
.pending-txn-container {
  display: flex;
  align-items: center;
  padding: 10px !important;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
    font-size: 14px;
    font-weight: 500;
  }
}
#rgk-popper-pending {
  .MuiPaper-root.rgk-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}

.dapp-nav-top {
  height: 70px;
  padding-top: 15px;
}

.dapp-nav-top a {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  text-decoration: "none" !important;
  border-bottom: 1px solid #b1b1b1;
  height: 100% !important;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
    vertical-align: -4px;
  }

  &:hover {
    text-decoration: none !important;
    color: #c73636;
    border-bottom: 3px solid #c73636;
    margin-bottom: 3px !important;
    &.active {
      color: #c73636;
    }
  }

  &.active {
    text-decoration: none !important;
    color: #c73636;
    border-bottom: 3px solid #c73636;
    margin-bottom: -2px !important;

    svg {
      fill: #c73636;
    }
  }
}
