.forfeit-btn {
  background: #d21a1a;
  box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
  border-radius: 10px;
  padding: 4px 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin-left: 30%;

  Button {
    font-family: Montserrat SemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #ffffff;
    min-width: 20%;
    padding: 4px 25px;
    text-transform: lowercase;
  }

  p {
    font-family: Montserrat SemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }
}

.cancel-btn {
  background: #7e7e7e;
  box-shadow: 0px 0px 10px rgba(44, 39, 109, 0.1);
  border-radius: 10px;
  padding: 4px 20px;
  cursor: pointer;
  text-align: center;
  display: inline-block;

  p {
    font-family: Montserrat SemiBold;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }
}
.modal-view {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 69;
  text-align: center;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .MuiBackdrop-root {
      background: rgba(100, 100, 100, 0.1) !important;
      backdrop-filter: blur(33px) !important;
      -webkit-backdrop-filter: blur(33px) !important;
    }
  }

  /* slightly transparent fallback for Firefox (not supporting backdrop-filter) */
  @supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .MuiBackdrop-root {
      background: rgba(62, 58, 113, 0.8);
    }
  }
}
