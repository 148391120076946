.rebase-timer {
  position: relative;

  p {
    font-family: Montserrat SemiBold;
    font-weight: 600;
    font-size: 12px;
    color: #ffffff;
  }
}
