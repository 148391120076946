#treasury-dashboard-view {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  // width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }

  .rgk-card {
    max-width: unset !important;
    width: 100%;
    margin: auto;
    .card-header {
      margin-bottom: 1rem;
    }
    &.rgk-chart-card {
      .chart-card-header {
        padding: 0px 20px !important;
        min-width: 300px;
      }
      height: 355px;
    }
  }
  .hero-metrics {
    width: 100%;
    margin-bottom: 23px;
    text-align: center;
    .rgk-card {
      max-width: unset !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      h5 {
        font-weight: bold;
      }
      div.metric {
        margin: 3px;
        min-width: 140px;
        width: 30%;
      }
    }
  }
  .data-grid {
    overflow: hidden;
  }
}

.smaller {
  .rgk-card {
    width: 97%;
  }
  .hero-metrics .rgk-card {
    > .MuiBox-root {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    div.metric {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 30% !important;
    max-width: 60%;
    text-align: left !important;
    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.very-small {
  .hero-metrics .rgk-card {
    > .MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}
